<!--*
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 *-->
<div class="c-modal-body c-text-center">
  <div
    class="c-d-flex c-align-center c-justify-center c-m-bottom-md c-m-top-md"
  >
    <span class="c-text-warning-5 c-m-right-sm c-text-lg">
      <i class="fa fa-exclamation-triangle"></i>
    </span>
    <span
      *ngIf="title"
      class="c-text-lg c-text-bold c-m-0"
      [innerHTML]="title"
    ></span>
  </div>
  <p *ngIf="text" class="c-modal-confirmation-text" [innerHTML]="text"></p>
  <p
    class="c-text-center c-modal-confirmation-text c-cursor-pointer"
    *ngFor="let item of checkboxes | keyvalue"
    (click)="toggleCheckbox(item.key)"
    (keydown.enter)="toggleCheckbox(item.key)"
  >
    <label class="c-checkbox">
      <input [checked]="item.value.value" type="checkbox" [name]="item.key" />
      <i class="c-bg-primary"></i>
    </label>
    <span>{{ item.value.title }}</span>
  </p>
  <div class="c-modal-confirmation-buttons">
    <span class="close-modal" aria-hidden="true">
      <button class="c-btn c-btn-default" (click)="onCancelModal()">
        {{ rejectText }}
      </button>
      <button class="c-btn c-btn-primary c-m-left-sm" (click)="onConfirm()">
        {{ confirmText }}
      </button>
    </span>
  </div>
</div>
