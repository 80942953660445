/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type EventEmitter, Injectable } from '@angular/core';

import {
  type BsModalRef,
  type ModalOptions,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { type Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import {
  type ConfirmationResultModel,
  type ConfirmationStateModel,
} from '@shared/confirmation-modal/confirmation-state.model';

@Injectable()
export class ModalService {
  public close$: Subject<BsModalRef> = new Subject();

  private readonly defaultConfig: object;
  private readonly activeModals: BsModalRef[] = [];

  constructor(protected modalService: BsModalService) {
    this.defaultConfig = {
      animated: true,
      ignoreBackdropClick: true,
    };
  }

  public get onHidden(): EventEmitter<void> {
    return this.modalService.onHidden;
  }

  public openModal(
    component: any,
    config?: ModalOptions,
    initialState?: object,
    closePreviousModal: boolean = true
  ): BsModalRef {
    if (closePreviousModal) {
      this.closeActiveModal();
    }
    const options = { ...this.defaultConfig, ...config };
    if (initialState) {
      options.initialState = initialState;
    }
    const activeModal = this.modalService.show(component, options);
    this.activeModals.push(activeModal);
    return activeModal;
  }

  public closeActiveModal(): void {
    if (this.activeModals.length) {
      const lastActiveModal = this.activeModals.pop();
      lastActiveModal.hide();
      this.close$.next(lastActiveModal);
    }
  }

  public showConfirmationModal(
    confirmationState: ConfirmationStateModel
  ): Observable<ConfirmationResultModel> {
    const initialState: ConfirmationStateModel = {
      confirmText: 'Yes',
      rejectText: 'No',
      ...confirmationState,
    };
    const modalRef = this.openModal(
      ConfirmationModalComponent,
      {},
      initialState
    );
    return modalRef.content.onHide.pipe(first());
  }
}
